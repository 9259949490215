@media only screen and (max-width: 1279px) {
  .lawyercardspace {
    padding: 0;
  }
  .lawyercardspace {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 1080px) {
  .lawyerlist-container {
    display: none;
  }
  .lawyerchat {
    width: 100%;
    height: 60vh;
  }
  .slider-div {
    display: block;
  }
  .logo img {
    width: 80%;
    margin: 0;
  }
  .lawyer-card {
    margin: 5px 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .searchbar-card{
    width: 70%;
  }
  .alert-bg-open {
    z-index: 10;
  }
  .hambergericon {
    display: block;
  }
  .ulli {
    display: none;
  }
  .logo-img {
    display: none;
  }
  .bg-card {
    position: absolute;
    bottom: 2%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  Button {
    width: 80px;
    height: 34px;
    font-size: 14px !important;
  }
  h1,
  h2 {
    font-size: 26px !important;
  }
  .logo img {
    width: 80%;
    margin: 0;
  }
  .logo {
    position: absolute;
    top: 10%;
    left: 20%;
  }
  .icon {
    color: white;
    font-size: 18px;
    padding: 8px 8px;
  }
  .purple-bg {
    height: 100vh;
  }
  .alert-bg {
    width: 250px;
    margin: 0 15px;
    background-color: #161619;
  }
}

@media only screen and (max-width: 767px) {
  .lawyerpic {
    width: 30%;
  }
  .searchbar-card {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 7%;
    width: 85%;    
    margin: 0 auto;
  }
  .alert-bg-open {
    position: absolute;
    top: 5%;
    right: 20%;
    left: auto;
    bottom: auto;
    z-index: 10;
  }

  .purple-bg {
    height: 6vh;
    width: 100%;
  }
  .menu-bar-icon {
    display: block;
  }
  .profile-popup-icon {
    position: absolute;
    right: 1%;
    top: 1%;
    left: auto;
    bottom: auto;
  }
  .ulli {
    display: none;
  }
  .logo-img img {
    display: none;
  }
  .lawyerchat {
    width: 100%;
    height: 35vh;
    padding: 0px 35px;
  }
  .bg-card {
    position: absolute;
    bottom: 2%;
    right: 0;
    left: 0;
    margin: 0 auto;
  }
  .slider-div {
    width: 100%;
    height: auto;
    padding: 20px 35px 0 35px;
  }
  Button {
    width: 80px;
    height: 34px;
    font-size: 14px !important;
  }
  h1,profile
  h2 {
    font-size: 26px !important;
  }
  .logo img {
    width: 80%;
    margin: 0;
  }
  .alert-bg {
    width: 250px;
    margin: 0 15px;
  }
  .hambergericon {
    display: block;
  }

  .puple-bg {
    display: none;
  }
  .form-control {
    font-size: 10px !important;
  }
}
