body{
  background: #111113 !important;
}
  /* .profile{
    position: absolute;
    left: 0;
    bottom: 0;
  } */
  .alert-bg{
    width: 300px;
    background-color: #161619;
  }
  .xmark-icon{
    color: #22133a;
    font-size: 16px;
    background-color: white;
    padding: 6px 8px;
    border-radius: 50%;
    display: table;
    margin: 0 0 0 auto;
  
  }
  .profileicons{
    color: #22133a;
    font-size: 12px;
    margin-top: 3px;
    background-color: white;
    padding: 4px 5px;
    border-radius: 50%;
    /* position: absolute;
    left: 0; */
  }
  .black-bg{
    background-color: #111113;
    background-image: url(../images/blackvector.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0;
    margin: 0;
    
  }
  .profile-img{
    text-align: center;
  }
  .profile-img img{
    border-radius: 50%;
    width: 100px;
    height: 100px;
    text-align: center;
  }
  .rate{
    text-align: center;
  }
  .rate img{
    width: 100px;
    text-align: center;
  }
  .padding{
    padding: 50px 0;
  }
  .number h3{
    text-align: center;
  }
  .details ul{
    padding: 0;
  }
  .details ul li{
    list-style-type: none;
    color: white;
    margin-bottom: 15px;
  }
  .details-icon{
    border: 1px solid white;
    border-radius: 50%;
    background-color: white;
    padding: 5px;
    margin-right: 10px;
    width: 16px;
    height: 16px;
   position: absolute;
  }
  .details p{
    margin-left: 35px;
    padding: 5px;
  }
 .width{
    width: 100%;
    max-width: 1300px !important;
 }
 .areas{
    padding: 30px 0;
 }
 .bg{
    background-color: #22133A;
    padding: 30px 0;
  }
  .bg-img{
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
  }
  .profile-head{
    position: relative;
  }
  .profile-section{
    position: absolute;
    bottom: -66px;
    left: 35px;
    width: 100%;
    padding-bottom: 50px;
  }
  .areas{
    padding: 60px 0 30px 0;
    justify-content: space-around;
  }
  .areas .about{
    border: 1px solid white;
    padding: 20px;
    width: 100%;
    border-radius: 5px;
  }
  .advocate-cards{
    justify-content: space-around;
    overflow-x: scroll;
    height: 100%;
    overscroll-behavior: auto;
    scrollbar-width: none;
  }
  .advocate .box{
    border: 1px solid white;
    padding: 20px;
    background-color: white;
    border-radius: 15px;
    box-shadow: 5px 10px 20px 5px rgb(214, 214, 214) inset;
    margin: 0 20px;
    overflow: hidden;
  }
  .box h4{
    color: black;
  }
  .box p{
    color: black;
  }
  .advocate{
    padding: 30px 60px 30px 60px;
  }
  .advocate .head{
    padding-bottom: 20px;
    text-align: center;
  }
  .slick-arrow{
    display: none !important;
  }
  .bg-lawyer{
    background-image: url(../images/bgimg.jpg);
    width: 100%;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center;
  }
  .bottom{
    padding-bottom: 120px;
  }
  .about h2{
    font-size: 23px;
  }
  .name{
    position: absolute;
    top: 54px;
  }
  .mail{
    position: absolute;
    top: 90px;
  }
  .name h2{
    font-size: 24px;
  }
  .modal-content{
    background-color: #22133A;
  }
  .modal-content .modal-footer{
    display: none;
  }
  .modal-title{
    color: white;
  }
  .modal-body h4{
    display: none;
  }
  .modal-header .btn-close{
    background-color: white;
  }
  .slick-dots{
    display: none !important;
  }
  .navbar{
    display: none;
  }
  /* .top-1{
   position: absolute;
   top: 110px;
  left: 220px;
 }
 .top-2{
  position: absolute;
  left: 0;
  bottom: 0;
  cursor: pointer;
}
.alert-bg{
  width: 300px;
  background-color: #161619;
}
.xmark-icon{
  color: #22133a;
  font-size: 16px;
  background-color: white;
  padding: 6px 8px;
  border-radius: 50%;
  display: table;
  margin: 0 0 0 auto;

}
.profileicons{
  color: #22133a;
  font-size: 12px;
  margin-top: 3px;
  background-color: white;
  padding: 4px 5px;
  border-radius: 50%;
  position: absolute;
  left: 0;
}
.black-bg{
  background-color: #111113;
  width: 100%;
  height: 100%;
  background-image: url(../images/blackvector.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0;
  margin: 0;
  
}
.profile-img{
  text-align: center;
}
.profile-img img{
  border-radius: 50%;
  width: 100px;
  height: 100px;
  text-align: center;
}
.rate{
  text-align: center;
}
.rate img{
  width: 100px;
  text-align: center;
}
.padding{
  padding: 50px 0;
}
.number h3{
  text-align: center;
}
.details ul{
  padding: 0;
}
.details ul li{
  list-style-type: none;
  color: white;
  margin-bottom: 15px;
}
.details-icon{
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;
  padding: 5px;
  margin-right: 10px;
  width: 16px;
  height: 16px;
 position: absolute;
}
.details p{
  margin-left: 35px;
  padding: 5px;
}
.width{
  width: 100%;
  max-width: 1300px !important;
}
.areas{
  padding: 30px 0;
}
.bg{
  background-color: #22133A;
  padding: 30px 0;
}
.bg-img{
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 5px;
}
.profile-head{
  position: relative;
}
.profile-section{
  position: absolute;
  bottom: -66px;
  left: 35px;
  width: 100%;
  padding-bottom: 50px;
}
.areas{
  padding: 60px 0 30px 0;
  justify-content: space-around;
}
.areas .about{
  border: 1px solid white;
  padding: 20px;
  width: 100%;
  border-radius: 5px;
}
.advocate-cards{
  justify-content: space-around;
  overflow-x: scroll;
  height: 100%;
  overscroll-behavior: auto;
  scrollbar-width: none;
}
.advocate .box{
  border: 1px solid white;
  padding: 20px;
  background-color: white;
  border-radius: 15px;
  box-shadow: 5px 10px 20px 5px rgb(214, 214, 214) inset;
  margin: 0 20px;
  overflow: hidden;
}
.box h4{
  color: black;
}
.box p{
  color: black;
}
.advocate{
  padding: 30px 60px 30px 60px;
}
.advocate .head{
  padding-bottom: 20px;
  text-align: center;
}
.slick-arrow{
  display: none !important;
}
.bg-lawyer{
  background-image: url(../images/bgimg.jpg);
  width: 100%;
  height: 150px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position:center;
}
.bottom{
  padding-bottom: 120px;
}
.about h2{
  font-size: 23px;
}
.name{
  position: absolute;
  top: 54px;
}
.mail{
  position: absolute;
  top: 90px;
}
.name h2{
  font-size: 24px;
}
.modal-content{
  background-color: #22133A;
}
.modal-content .modal-footer{
  display: none;
}
.modal-title{
  color: white;
}
.modal-body h4{
  display: none;
}
.modal-header .btn-close{
  background-color: white;
}
.slick-dots{
  display: none !important;
}
.navbar{
  display: none;
}
.ulli{
  width: 100%;
  height: 84vh;
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: thin;
  padding-right: 18px;
}
.ulli ul li{
  color: white;
  list-style-type: none;
  margin: 0 0 14px 0;


}
.ulli span{
  color: rgb(255, 255, 255,.6);
  
}
/* .top-1{
 position: absolute;
 top: 110px;
left: 220px;
}
.top-2{
position: absolute;
top: 153px;
left: 850px;
} */
@import url('https://fonts.googleapis.com/css?family=Varela+Round');
html, body {
  overflow-x: hidden;
  height: 100%;
}
body {
    background: #111113; 
    padding: 0;
    margin: 0;
}
.header {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 100%;
  box-shadow: none;
  /* background-color: #FC466B; */
  position: fixed;
  height: 60px!important;
  overflow: hidden;
  z-index: 10;
}
.main {
  margin: 0 auto;
  display: block;
  height: 100%;
  margin-top: 60px;
}
.mainInner{
  display: table;
  height: 100%;
  width: 100%;
  text-align: center;
}
.mainInner div{
  display:table-cell;
  vertical-align: middle;
  font-size: 3em;
  font-weight: bold;
  letter-spacing: 1.25px;
}
#sidebarMenu {
  height: 100%;
  position: fixed;
  left: 0;
  width: 250px;
  margin-top: 60px;
  transform: translateX(-250px);
  transition: transform 250ms ease-in-out;
  background: linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%);
}
.sidebarMenuInner{
  margin:0;
  padding:0;
  border-top: 1px solid rgba(255, 255, 255, 0.10);
}
.sidebarMenuInner li{
  list-style: none;
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  padding: 5px 20px;
  cursor: pointer;
}
.sidebarMenuInner li span{
  display: block;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.50);
}
.sidebarMenuInner li a{
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
input[type="checkbox"]:checked ~ #sidebarMenu {
  transform: translateX(0);
}

input[type=checkbox] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}
.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 99;
  height: 100%;
  width: 100%;
  top: 22px;
  left: 15px;
  height: 22px;
  width: 22px;
}
.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}
.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
}
.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  margin-top: 3px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 8px;
}
input[type=checkbox]:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}
.logo-img{
/* padding: 45px; */
margin: 0;
}
.alert-res{
display: none;
}






.hambergericon{
  display: none;
   color: #22133a;
    font-size: 20px;
    background-color: white;
    padding: 8px 10px;
    border-radius: 50%;
    position: absolute;
    left: 1.5%;
    top: 1%;

}

.ul-li-toggle{
  width: 100%;
  height: 84vh;
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: thin;
  padding-right: 18px;
}
.ul-li-toggle ul li{
  color: white;
  list-style-type: none;
  margin: 0 0 14px 0;
}
.ul-li-toggle span{
  color: rgb(255, 255, 255,.6);
}

.ulli{
  width: 100%;
  height: 84vh;
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: thin;
  padding-right: 18px;
}
.ulli ul li{
  color: white;
  list-style-type: none;
  margin: 0 0 14px 0;


}
.ulli span{
  color: rgb(255, 255, 255,.6);
  
}
.offcanvas-header{
  background-color: #22133a !important;
}
.offcanvas-header .btn-close{
  background-color: white;
}
.offcanvas-body{
  background-color: #22133a !important;
}
.profile-popup-icon{
  position: absolute;
  bottom: 0;
  left: 0;
  cursor: pointer;

}
.alert-bg-open{
  width: 200px;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: #161619;
}
.profile-popup-icon ul li {
  list-style-type: none;
  color: white;
  margin: 20px 0;
}