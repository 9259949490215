@media only screen and (max-width: 1279px)  {
    .areas {
        padding: 85px 0 50px 0;
        align-items: center;
    }
    .areas .about{
        width: 100%;
    }
    .navbar{
        display: none;
    }
    .black-bg{
        z-index: 0;
        height: 100%;
    }
    .purple-bg{
        height: 100%;
        padding-right: 20px;
    }
    .sidebarMenuInner{
        border-top: 0;
    }
    .profile-top{
        z-index: 1;
    }
    #sidebarMenu{
        background: #22133a;
        margin-top: -10px;
    }
    .sidebarMenuInner{
        margin-top: 90px;
    }
    .logo-img{
        padding: 22px;
    }
    .logo-img img{
        width: 100%;
        display: block;
    }
    .advocate{
        padding: 0;
    }
    #sidebarMenu{
        z-index: 2;
        overflow-y: scroll;
        overscroll-behavior: auto;
        scrollbar-width: thin;
        overflow-x: hidden;
    }
    #sidebarMenu ul li{
        color: white;
        list-style-type: none;
        margin: 0 0 20px 0;
    }
    #sidebarMenu span{
        color: rgb(255, 255, 255,.6);
        padding-left: 20px;
    }
    .nav-logo{
        text-align: center;
        padding-bottom: 50px;
    }
    .sidebarMenuInner li{
        text-transform: capitalize;
        font-weight: normal;
    }
    .alert-bg{
        z-index: 2;
    }
    .home-nav{
        display: block;
    }
    .ulli{
        padding-right: 0;
    }
    .profile-popup-icon{
        left: 15px;
    }
    }
    
    @media only screen and (max-width: 1023px) {
        .mail {
            position: absolute;
            top: 90px;
            right: 16px;
        }
        .areas .about{
            width: 95%;
        }
        .areas {
            padding: 65px 0 65px 0;
        }
        .navbar{
            display: block;
        }
        #sidebarMenu{
            background: #22133a;
            margin-top: -10px;
        }
        .sidebarMenuInner{
            margin-top: 90px;
        }
        .logo-img img{
            width: 80%;
            display: none;
        }
        .advocate{
            padding: 0;
        }
        .black-bg{
            z-index: 0;
        }
        .sidebarMenuInner{
            border-top: 0;
        }
        .profile-top{
            z-index: 1;
        }
        #sidebarMenu{
            z-index: 2;
            overflow-y: scroll;
            overscroll-behavior: auto;
            scrollbar-width: thin;
            overflow-x: hidden ;
        }
        #sidebarMenu ul li{
            color: white;
            list-style-type: none;
            margin: 0 0 20px 0;
        }
        #sidebarMenu span{
            color: rgb(255, 255, 255,.6);
            padding-left: 20px;
            border-top: 1px solid rgba(255, 255, 255, 0.10);
            padding: 20px;
        }
        .nav-logo{
            text-align: center;
            padding-bottom: 50px;
        }
        .sidebarMenuInner li{
            text-transform: capitalize;
            font-weight: normal;
        }
    
    }
    
    @media (min-width: 820px) and (max-width: 1180px){
        .areas{
            padding: 60px 0 60px 0;
        }
        .profile{
            left: 8px;
        }
        .sidebarIconToggle{
            left: 14px;
        }
        .home-nav{
            display: none;
        }
    }
    
    @media (min-width: 768px) and (max-width: 991px){
        .hambergericon{
            display: block;
          }
          .ulli{
            display: none;
          }
        .bg-card {
          position: absolute;
            bottom: 2%;
            right: 0;
            left: 0;
            margin: 0 auto;
          width: 66%;
        }
        



        /* .top-2 {
            position: absolute;
            top: 153px;
            left: 535px;
        }
        .top-1 {
            position: absolute;
            top: 110px;
            left: 192px;
        } */
        .mail {
            position: absolute;
            top: 90px;
            right: 83px;
        }
        .areas .about{
            width: 85%;
        }
        .about{
            margin: 0 auto;
        }
        .black-bg{
            height: 100vh;
        }
        .navbar{
            display: block;
        }
        #sidebarMenu{
            background: #22133a;
            margin-top: -10px;
        }
        .sidebarMenuInner{
            margin-top: 90px;
        }
        .logo-img img{
            width: 80%;
            display: none;
        }
        .advocate{
            padding: 0;
        }
        .black-bg{
            z-index: 0;
        }
        .sidebarMenuInner{
            border-top: 0;
        }
        .profile-top{
            z-index: 1;
        }
        #sidebarMenu{
            z-index: 2;
            overflow-y: scroll;
            overscroll-behavior: auto;
            scrollbar-width: thin;
        }
        #sidebarMenu ul li{
            color: white;
            list-style-type: none;
            margin: 0 0 20px 0;
        }
        #sidebarMenu span{
            color: rgb(255, 255, 255,.6);
            padding-left: 20px;
        }
        .nav-logo{
            text-align: center;
            padding-bottom: 50px;
        }
        .sidebarMenuInner li{
            text-transform: capitalize;
            font-weight: normal;
        }
        .alert-bg{
            z-index: 2;
        }
        .profile{
            left: 0;
        }
        .sidebarIconToggle{
            left: 16px;
        }
        .home-nav{
            display: none;
        }
        .about{
            margin-bottom: 20px;
        }
        .hambergericon{
            top: 1%;
            left: 1%;
        }
        .areas {
            padding: 65px 0 40px 0;
        }
        .profile-popup-icon {
            left: 0px;
        }
    }
    
    @media only screen and (max-width: 767px) {
        .alert-bg-open{
            position: absolute;
            top: 5%;
            right: 20%;
            left: auto;
            bottom: auto;
            z-index: 10;
          }
        .purple-bg {
            height: 6vh;
            width: 100%;
        }
        .profile-popup-icon {
            position: absolute;
            right: 1%;
            top: 1%;
            left: auto;
            bottom: auto;
        }
          .ulli{
            display: none;
          }
        .alert-bg {
            width: 250px;
            margin: 0 15px;
          }
        .hambergericon{
            display: block;
          }
        
          .puple-bg {
            display: none;
          }
        
        



        .black-bg{
            height: 100%;
        }
        .profile{
            left: 10px;
        }
        .profile-section {
            bottom: -38px;
            left: 33px;
        }
        .areas {
            padding: 130px 0 65px 0;
        }
        .mail {
            top: 190px;
            right: 33px;
        }
        .name {
            top: 152px;
            left: 0;
        }  
        .profile-top{
            padding-right: 38px;
        }
        #sidebarMenu ul li{
            font-size: 12px;
        }
        .home-nav{
            display: none;
        }
        .hambergericon{
            top: 1%;
            left: 1%;
        }
        
    
    }
    
    @media (min-width: 421px) and (max-width: 766px){
        .profile{
            margin: 0 0 0 auto;
            display: table;
            top: 8px;
            position: absolute;
            right: 0;
            z-index: 11;
        }
        .logo-img{
            padding: 25px;
        }
        .profile-top{
            padding-right: 38px;
        }
        .alert-bg{
            position: absolute;
            top: 55px;
            right: 0;
        }
        .home-nav{
            display: none;
        }
        .profile-top {
            padding-right: 56px;
        }
        .name {
            left: 0 !important;
        }
        .mail{
            top: 246px;
            left: 0;
        }
        .details-icon {
            width: 10px;
            height: 10px;
        }
        .details p{
            font-size: 10px;
        }
        .areas {
            padding: 145px 0 40px 0 !important;
        }
        .details ul li {
            margin-bottom: -12px;
        }
        .areas .about {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 25px;
        }
        .purple-bg{
            position: fixed;
            height: 60px;
            z-index: 999;
            left: 0;
            right: 0;
        }
        .sidebarIconToggle{
            top: 15px;
            left: 10px;
        }
        .hambergericon{
            top: 18%;
            left: 3%;
        }
        .profile-popup-icon{
            top: 11%;
        }
    }
    
    @media only screen and (max-width: 420px) {
       
    }
    @media (min-width: 280px) and (max-width: 419px){
        .black-bg{
            height: 100%;
        }
        .profile{
            right: 6px !important;
        }
        .profile-section {
            bottom: -38px;
            left: 33px;
        }
        .areas {
            padding: 145px 0 40px 0 !important;
        }
        .mail {
            top: 186px;
            right: 16px;
        }
        .name {
            top: 152px;
            left: -15px;
        }
        .profile{
            margin: 0 0 0 auto;
            display: table;
            top: 8px;
            position: absolute;
            right: 0;
            z-index: 11;
        }
        .logo-img{
            padding: 25px;
        }
        .profile-top{
            padding-right: 38px;
        }
        .alert-res{
            display: block !important;
        }
          .alert-bg{
            position: absolute;
            top: 55px;
            right: 0;
        }
        #sidebarMenu ul li{
            font-size: 12px;
        }
        .home-nav{
            display: none;
        }
        .profile-top {
            padding-right: 56px;
        }
        .name {
            left: 0 !important;
        }
        .mail{
            top: 246px;
            left: 0;
        }
        .details-icon {
            width: 10px;
            height: 10px;
        }
        .details p{
            font-size: 10px;
        }
        .areas {
            padding: 145px 0 65px 0;
        }
        .details ul li {
            margin-bottom: -12px;
        }
        .areas .about {
            width: 90%;
            margin: 0 auto;
            margin-bottom: 25px;
        }
        .purple-bg{
            position: fixed;
            height: 60px;
            z-index: 999;
            left: 0;
            right: 0;
        }
        .sidebarIconToggle{
            top: 15px;
            left: 10px;
        }
        .hambergericon{
            top: 18%;
            left: 3%;
        }
        .profile-popup-icon{
            top: 11%;
        }
    }
    
    
    
    @media (min-width: 360px) and (max-width: 400px){
        .mail {
            right: 13px;
        }
        .name {
            left: -20px;
        }
    }
    
    