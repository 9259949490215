@font-face {
  font-family: Imported;
  src: url(../../font/Asap-VariableFont_wdth\,wght.ttf);
}
#root {
  font-family: Imported;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}
p {
  color: white;
  font-size: 14px;
}
.alert-bg-open {
  width: 200px;
  position: absolute;
  left: 50%;
  bottom: 0;
  background-color: #161619;
  z-index: 10;
}

.dark-bg {
  width: 100% !important;
  height: 100vh;
  background-image: url(../images/background.png);
  background-repeat: no-repeat;
  background-size: cover;
}

hr {
  color: rgb(227, 216, 216);
  border-top: 2px solid;
  margin: 8px 0;
}
.logo-img {
  text-align: center;
}
.logo-img img {
  width: 80%;
  padding: 20px 0;
}
.hambergericon {
  display: none;
  color: #22133a;
  font-size: 15px;
  background-color: white;
  padding: 6px 8px;
  border-radius: 50%;
  position: absolute;
  left: 1.5%;
  top: 1%;
}
.profileicons {
  color: #22133a;
  font-size: 12px;
  margin-top: 3px;
  background-color: white;
  padding: 4px 5px;
  border-radius: 50%;
}

.xmark-icon {
  color: #22133a;
  font-size: 16px;
  background-color: white;
  padding: 6px 8px;
  border-radius: 50%;
  display: table;
  margin: 0 0 0 auto;
}
.profile-icon {
  color: #22133a;
  font-size: 15px;
  background-color: white;
  padding: 6px 8px;
  border-radius: 50%;
  margin: 5px 10px;
}
.purple-bg {
  background-color: #22133a;
  height: 100vh;
  width: 100%;
}
.black-bg {
  background-image: url(../images/blackvector.png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100% !important;
}
.puple-bg {
  width: 100% !important;
  background-color: #22133a;
  height: 100vh;
  background-image: url(../images/starbg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}
a {
  color: white;
}

Button {
  width: 110px;
  height: 46px;
  font-size: 20px !important;
  font-weight: 500;
  background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
  border-radius: 40px;
  border: none !important;
  margin: 20px 10px 0 0;
}

.relative {
  position: relative;
}
.text-align {
  text-align: center;
}

.card-bg p {
  color: black;
  margin: 0;
}

.send-button {
  color: white;
  background-color: #22133a;
  border-radius: 50%;
  padding: 4px 6px;
  position: absolute;
  right: 5%;
  top: 52%;
}

.card-bg {
  background-color: white;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 0 0 20px 0;
}
.chat-logo {
  padding-top: 50px;
  text-align: center;
}
.slider-div {
  display: none;
  width: 100%;
  height: auto;
  padding-top: 20px;
}
.slider-width {
  width: 98% !important;
}
.textwhite {
  color: white;
}
.textspace {
  padding: 10px 5px;
}
.icon {
  color: white;
  font-size: 22px;
  padding: 10px 10px;
  border-radius: 50%;
  margin-bottom: 30px;
  background: linear-gradient(
    90deg,
    rgba(63, 94, 251, 1) 0%,
    rgba(252, 70, 107, 1) 100%
  );
}
.linktext {
  text-decoration: underline;
}

.border-none {
  border: none;
}
.profile ul li {
  list-style-type: none;
  color: white;
  margin: 20px 0;
}
.profile-popup-icon ul li {
  list-style-type: none;
  color: white;
  margin: 20px 0;
}
.result-icon {
  color: #22133a;
  font-size: 14px;
  background-color: white;
  padding: 4px 6px;
  border-radius: 50%;
  position: absolute;
  left: -2.5%;
}
.div-pad {
  padding-top: 30px;
}

.form-control:focus {
  border: none !important;
  box-shadow: none !important;
}
.enter-button {
  color: white;
  background-color: #22133a;
  border-radius: 50%;
  padding: 8px 10px;
  margin: 3px 0 0 auto;
  display: table;
  cursor: pointer;
}
.aitext-center{
  display: flex;
}
.bg {
  background-color: #22133a;
}
.msclass {
  margin: 0 !important;
  padding: 0 !important;
}
.offcanvas-header {
  background-color: #22133a !important;
}
.offcanvas-header .btn-close {
  background-color: white;
}
.offcanvas-body {
  background-color: #22133a !important;
}
.profile-popup-icon {
  position: absolute;
  bottom: 0;
  left: 0.6%;
  cursor: pointer;
}
.lawyer-card {
  background-color: white;
  border-radius: 10px;
  margin: 30px 0;
  padding: 14px 18px;
}
.lawyer-card h6 {
  color: black;
  margin-bottom: 3px;
}
.lawyer-card p {
  color: black;
  margin: 0 !important;
}
.lawyerpic {
  width: 100%;
  height: auto;
  border-radius: 50%;
}
.lawyer-card button {
  font-size: 15px !important;
  margin: 0 !important;
}
.rating {
  font-size: 16px;
  margin-left: 20px;
}
.staricon {
  color: rgb(250, 234, 13) !important;
}
.circle-rate {
  position: absolute;
  top: 0%;
  background-color: black;
  color: white;
  padding: 0px 5px;
  border-radius: 0px 5px 0 0 !important;
  right: 0%;
}
.alignice {
  align-items: center;
}
.lawyerlist-container {
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: none;
  height: 90vh;
  width: 100%;
  padding-top: 90px;
}
.lawyerbox {
  width: 85%;
  background-color: white;
  border-radius: 10px;
  margin: 30px 0;
  padding: 14px 18px 12px 18px;
}
.lawyerbox p {
  color: black !important;
  margin: 0 !important;
}
.lawyerbox h2,
h6 {
  color: black !important;
  margin: 0 !important;
}
.lawyercardspace {
  padding: 7px 5px 0 5px;
}
.navbar {
  display: block;
}
.offcanvas{
  max-width: 60%;
}
.ulli {
  width: 90%;
  height: 83vh;
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: none;
  padding-right: 18px;
  padding: 0 16px
}
.ulli ul li {
  color: white;
  list-style-type: none;
  margin: 0 0 14px 0;
}
.ulli span {
  color: rgb(255, 255, 255, 0.6);
}
.ul-li-toggle {
  width: 100%;
  height: 84vh;
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: thin;
  padding-right: 18px;
}
.ul-li-toggle ul li {
  color: white;
  list-style-type: none;
  margin: 0 0 14px 0;
}
.ul-li-toggle span {
  color: rgb(255, 255, 255, 0.6);
}

.lawyerchat {
  width: 92%;
  text-align: justify;
  height: 79vh;
  overflow-y: scroll;
  overscroll-behavior: auto;
  scrollbar-width: none;
  margin: 0 auto;
}
.lawyerchaticon {
  background-image: url(../images/aiicon.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 25px;
  height: 25px;
  padding: 10px 5px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: -9%;
}
.lawyerchaticonone {
  color: #22133a;
  font-size: 12px;
  background-color: white;
  padding: 4px 6px;
  border-radius: 50%;
  position: absolute;
  left: 0;
}
.lawyerchat h6 {
  color: white !important;
  padding-left: 30px;
  padding: 0 0 10px 30px;
}
.bg-card {
  background-color: white;
  border-radius: 20px;
  padding: 5px 0;
  position: absolute;
    bottom: 2%;
    right: 0;
    margin: 0 auto;
    left: 0;
    width: 80%;
}
.searchbar-div {
  background-color: white;
  border-radius: 20px;
  padding: 5px 0;
}
.search-height {
  height: 100vh;
}
.searchbar-card {
  position: absolute;
  right: 0;
  left: 0;
  margin: 0 auto;
  bottom: 7%;
  width: 60%;
  /* overflow-y: hidden; */
}
.scroll_update{
  height: 560px;
  overflow-y: scroll;
}
 
.scroll_update::-webkit-scrollbar {
  display: none;
} 

.introcentertext {
  text-align: center;
}
.justify-content {
  display: flex;
  align-items: center;
  justify-content: center;
}
.supporttext {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.text-search {
  height: 100vh;
}
.signup-box {
  padding: 0 20px 0 40px;
}
.searchtext {
  display: flex;
  align-items: center;
}
.faicons-chat {
  font-size: 8px;
  color: white;
  background-color: #22133a;
  border-radius: 50%;
  padding: 2px 4px;
}

.fontSize{
  font-size: 1rem;
}

